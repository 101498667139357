/* Reset Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Base body styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: #1a1a1a;
  color: #ffffff;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Main App container */
.App {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* Content Section */
.content {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Content typography */
.content > h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.content > p {
  font-size: 1.2rem;
  line-height: 1.6;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .content {
    padding: 10px;
  }
  .content > h1 { font-size: 2rem; }
  .content > p { font-size: 1rem; }
}